var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "page-filters-section",
        {
          attrs: {
            "top-img": "/img/enhancement/enhancement-header.png",
            overlay: false,
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center flex-column" },
            [
              _c("h1", [_vm._v("Bottomless bag")]),
              _c("span", [_vm._v("Collection of your Enhancements")]),
            ]
          ),
        ]
      ),
      !_vm.isWalletConnected
        ? _c("v-row", { staticClass: "page-content pt-10" }, [
            _c("div", { staticClass: "col" }, [_c("connect-wallet")], 1),
          ])
        : _vm._e(),
      _vm.isWalletConnected
        ? _c(
            "v-row",
            { staticClass: "page-content" },
            [
              _vm.loading
                ? _c("v-progress-circular", {
                    staticStyle: { width: "100% !important" },
                    attrs: { size: "300", indeterminate: "" },
                  })
                : _vm._e(),
              0 === _vm.enhancements.length && !_vm.loading
                ? _c("div", { staticClass: "col" }, [
                    _c("h3", { staticClass: "text-center mt-10" }, [
                      _vm._v("- No Enhancements -"),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.enhancements, function (enh) {
                return _c(
                  "v-col",
                  {
                    key: enh.id,
                    staticClass: "d-flex",
                    attrs: { cols: "6", sm: "auto" },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "Enhancement", params: { id: enh.id } },
                        },
                      },
                      [_c("mini-enhancement", { attrs: { item: enh } })],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }