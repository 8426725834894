<template>
    <v-container fluid>
        <page-filters-section top-img="/img/enhancement/enhancement-header.png" :overlay="false">
            <div class="d-flex align-center justify-center flex-column">
                <h1>Bottomless bag</h1>
                <span>Collection of your Enhancements</span>
            </div>
        </page-filters-section>

        <v-row v-if="!isWalletConnected" class="page-content pt-10">
            <div class="col">
                <connect-wallet></connect-wallet>
            </div>
        </v-row>

        <v-row v-if="isWalletConnected" class="page-content">
            <v-progress-circular
                v-if="loading"
                size="300"
                indeterminate
                style="width: 100% !important;"
            ></v-progress-circular>
            <div v-if="0 === enhancements.length && !loading" class="col">
                <h3 class="text-center mt-10">- No Enhancements -</h3>
            </div>
            <v-col cols="6" sm="auto" v-for="enh in enhancements" :key="enh.id" class="d-flex">
                <router-link :to="{ name: 'Enhancement', params: {id: enh.id}}">
                    <mini-enhancement :item="enh"></mini-enhancement>
                </router-link>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import PageFiltersSection from "../components/page-filters-section";
import {apiServiceVue} from "../services/apiService.vue";
import MiniEnhancement from "../components/assets/enhancements/mini-enhancement";
import EnhancementContract from "../services/contracts/enhancementContract";
import ConnectWallet from "@/views/ConnectWallet";

export default {
    components: {ConnectWallet, MiniEnhancement, PageFiltersSection},
    data() {
        return {
            enhancementContract: null,
            enhancements: [],
            loading: true,
        };
    },
    watch: {},
    mounted: function () {
        if (this.isWalletConnected) {
            this.fetchMyEnhancements();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.fetchMyEnhancements();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
    },
    methods: {
        async fetchMyEnhancements() {
            this.enhancementContract = new EnhancementContract(this.web3);
            this.loading = true;
            this.enhancements = [];

            this.enhancementContract.getMyBalanceOfEnhancementsBatch()
                .then((res) => {
                    let ids = [];
                    res.forEach((amount, id) => {
                        if (amount > 0) {
                            ids.push(id);
                        }
                    });

                    if (ids.length > 0) {
                        const url = process.env.VUE_APP_ENHANCEMENT_NFT_SERVER_URL + ids;
                        let enhancements = [];
                        apiServiceVue.request(url).then((res2) => {
                            ids.forEach((id) => {
                                // empty item (show anything in case missing in DB)
                                let enh = {
                                    name: '#' + id,
                                    description: '?',
                                    img: ''
                                };
                                if (res2.hasOwnProperty(id)) {
                                    enh = res2[id];
                                    enh.amount = res[id];
                                }
                                enhancements.push(enh);
                            });
                        });
                        this.enhancements = enhancements;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
}
</script>

<style scoped lang="scss">
.page-content {
    margin-top: 200px;
    padding: 0 20px 0 0;
}

.container--fluid {
    margin: 0 50px;
}

@media only screen and (max-width: $lg) {
    .container--fluid {
        margin: 0 20px;
    }
    .page-content {
        padding: 0 10px 0 0;
    }
}

@media only screen and (max-width: $sm) {
    .container--fluid {
        margin: 0;
    }
    .page-content {
        padding: 0;
    }
}
</style>
